<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addDoctor'})" :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">الأطباء</dashboard-page-title>
    <main-table :fields="fields" :allStatus="allStatus" list_url="admin/doctors" :baseURL="VUE_APP_VETE_LINK"  :additional-url="`&status=accepted`" :reloadData="reloadData"></main-table>

    <b-modal id="suspendedModal" size="lg" title="سبب ايقاف الدكتور" style="direction: rtl" centered hide-footer hide-header-close>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="d-flex flex-wrap">
          <b-col>
            <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="handleSuspendDoctor">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
import api from '../../services/index'
export default {
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      reloadData: false,
      saveButton: false,
      suspendedId: '',
      reason: '',
      allStatus: [
        {
          value: 'pending',
          text: 'قيد الإنتظار'
        },
        {
          value: 'refused',
          text: 'تم الرفض'
        },
        {
          value: 'accepted',
          text: 'نشط'
        }
      ],
      fields: [
        { label: 'ID', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم الطبيب', key: 'full_name', class: 'text-right' },
        { label: 'اسم العيادة', key: 'clinic_name', class: 'text-right' },
        { label: 'الحالة الوظيفية', key: 'type', class: 'text-right' },
        { label: 'عدد الكشوفات', key: 'examinations_count', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ التسجيل', key: 'created_at', class: 'text-right' },
        { label: 'نسبة العمولة', key: 'fees', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'view Veterinary',
              icon: 'ri-eye-fill',
              color: 'primary',
              ifNavigate: true,
              routePath: 'show-doctor/:id'
            },
            {
              text: 'edit Veterinary',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routePath: 'edit-doctor/:id'
            },
            {
              text: 'suspend Veterinary',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              actionParams: ['id'],
              actionName: 'showSuspensedModal'
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    },
    showSuspensedModal (data) {
      this.suspendedId = data.id
      this.$bvModal.show('suspendedModal')
    },
    handleSuspendDoctor () {
      this.saveButton = true
      api.statusDoctor(this.suspendedId, 'suspended', { mail_body: this.reason }).then(res => {
        core.showSnackbar('success', 'تم  ايقاف الدكتور بنجاح')
        this.saveButton = false
        this.$bvModal.hide('suspendedModal')
        this.reloadData = true
      })
      this.reloadData = false
    }
  },
  created () {
    this.$root.$on('showSuspensedModal', this.showSuspensedModal)
  }
}
</script>
